@font-face {
	font-family: SFProDisplay;
	src: url('../../fonts/FontsFree-Net-SFProDisplay-Regular.ttf');
}

img {
	max-width: 100%;
}

body {
	margin: auto;
	background-color: #c5c5c5;
	font-family: SFProDisplay;
	color: #222222;
}

video {
	width: 100%;
	max-width: 100%;
}

* {}

.view-card {
	margin-bottom: 22px;

	.card-header {
		background-color: #3072dd;
		text-align: center;
		padding: 3px;
		position: fixed;
		left: 0;
		right: 0;
		z-index: 99;

		.header-logo {
			display: inline-block;
			background-color: white;
			padding: 0px 18px;
			border: 1px solid #707070;
		}
	}

	.header-shape {
		position: fixed;
		background-color: #3072dd;
		width: 100%;
		height: 0;
		border-top: 100px solid #3072dd;
		border-right: 100px solid transparent;
	}

	.after-header {
		height: 80px;
	}

	.card-content {
		max-width: 350px;
		margin: auto;
		overflow: hidden;
		border-radius: 23px;
		box-shadow: 0px 5px 6px 3px #0000008a;
		background-color: white;
		padding-bottom: 16px;

		.card-loading {
			text-align: center;
			margin-top: 18px;
			padding-top: 20px;
		}

		.header-area {
			position: relative;

			.profile-cover {
				margin-bottom: 75px;

				img {
					width: 100%;
					border-radius: 23px 23px 0 0;
				}
			}

			.profile-photo {
				position: absolute;
				left: calc(50% - 75px);
				bottom: -75px;
				border-radius: 50%;
				overflow: hidden;
				width: 150px;
				height: 150px;
				border: 3px solid white;

				img {
					width: 100%;
				}
			}
		}

		.basic-area {
			text-align: center;
			padding-top: 6px;
			padding-bottom: 10px;

			.info-name {
				font-size: 25px;
				color: black;
			}

			.info-position {
				margin-top: 9px;
				font-size: 16px;
			}

			.info-location {}
		}

		.action-area {
			padding: 10px 0;
			display: flex;
			justify-content: space-evenly;

			.action-single {
				text-align: center;
				cursor: pointer;

				.action-icon {
					font-size: 22px;
				}

				.action-name {}
			}
		}

		.tabs-area {
			.single-tab {
				margin-bottom: 1px;

				.Collapsible .Collapsible__trigger {
					display: block;
					background-color: red;
					cursor: pointer;
					padding: 18px 12px;
					font-size: 17px;
					font-weight: bold;
					color: white;
					background-color: #3d141e;
				}

				.Collapsible__contentInner {
					padding: 8px;
				}

				.list-videos {

					.playlist {
						padding-top: 6px;
						display: grid;
						grid-column-gap: 3px;
						grid-template-columns: auto auto auto auto;

						.item-single {
							cursor: pointer;
						}
					}
				}

				.recruiting-profile {
					text-align: center;
				}
			}
		}
	}
}

.guest-form {
	position: fixed;
	background: #00000094;
	z-index: 101;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.area-form {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: white;
		border-radius: 18px 18px 0 0;
		padding: 12px;

		.form-header {
			display: flex;
			justify-content: space-between;
			border-bottom: 2px solid #d7d7d9;
			padding-bottom: 9px;

			.header-title {
				font-size: 20px;
				font-weight: bold;
			}

			.header-submit {
				button {
					clear: both;
					border: none;
					background: none;
					color: #4cae50;
					font-weight: bold;
					font-size: 20px;
					cursor: pointer;

					&:focus {
						outline: none;
					}
				}
			}

		}

		.form-fields {
			padding: 18px 0;

			.single-filed {
				margin-bottom: 8px;

				.filed-label {
					display: block;
					color: #a4a4a5;
					font-size: 14px;
					font-weight: normal;
				}

				.filed-input {
					width: calc(100% - 4px);
					padding: 3px;
					outline: none;
					border: none;
					border-bottom: 2px solid #e6e6e8;
				}
			}
		}
	}
}